import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { ImHome } from "react-icons/im"
import { Link, useStaticQuery, graphql } from "gatsby"

const Success = (props) => {

    const {settings} = useStaticQuery(graphql`
        query {
            settings: file(relativePath: {eq: "settings/settings-contact.md"}) {
                id
                childMarkdownRemark {
                    frontmatter {
                        title: frmsuccesstitle
                        description: frmsuccessdescription
                        seotitle: frmsuccessseotitle
                        seometadesc: frmsuccessseometadesc
                    }
                }
            }
        }
    `)

    const pagedata = settings.childMarkdownRemark

    return (
        <>
            <Layout location={props.location}>
                <SEO title={pagedata.frontmatter.seotitle} description={pagedata.frontmatter.seometadesc} />
                <section className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                      <ul className="text-left text-xs md:text-base">
                        <li className="inline-block">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-1">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                          <span className="px-1">Success</span>
                        </li>
                      </ul>
                    </div>
                    <div className="mx-auto px-2">
                        <div className="text-left cmscontent">
                            <h1>{pagedata.frontmatter.title}</h1>
                            <div className="text-left lg:text-justify cmscontent">{pagedata.frontmatter.description}</div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Success